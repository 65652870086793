$body-color: #102E4A;
$link-color: #0063cd;

img {
  margin-bottom: 30px;
}

H1 {
  color: #102E4A;
  font-weight: 700 !important;
  margin-bottom: 30px;
  font-family: 'Open Sans', sans-serif;
}

@media screen and (min-width: 767px) and (max-width: 992px) {
  H1 {
      font-size: 30px;
  }
}

H2 {
  color: #102E4A;
  font-weight: 700 !important;
  font-family: 'Open Sans', sans-serif;
}

H3 {
  color: #102E4A;
  font-family: 'Open Sans', sans-serif;
}

H4 {
  color: #102E4A;
  margin-bottom: 30px;
  font-family: 'Open Sans', sans-serif;
}

p, li, a {
  font-family: 'Open Sans', sans-serif;
}

small {
  font-size: 75%;
  line-height: .75 !important;
}

.container > .row {
  padding: 70px 0;
}

@media screen and (max-width: 992px) {
  .container > .row {
    padding: 40px 0;
  }
}

.text-white {
  color: #ffffff;
}

.text-purple-1 {
  color: #5887FF;
}

.text-purple-2 {
  color: #7b47ff;
  font-weight: 700;
}

.text-bold {
  font-weight: 700;
}

.box {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  height: 100%;
  position: relative;
}

.box.grey {
  background-color: #F5F5F5;
}

.box.vertical {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}

.box.vertical h3 {
  word-wrap: break-word;
}

.box.box-auto {
  height: auto;
}

.box img {
  margin-bottom: 30px;
}

.box .box-button-wrapper {
  justify-content: flex-end;
  display: flex;
  text-align: center;
}

.bg-grey-1 {
  background-color: #E1E1E1;
}

.bg-grey-2 {
  background-color: #F5F5F5;
}

.bg-grey-3 {
  background-color: #E5E5E5;
}

img.img-round.social {
  margin-bottom: 0;
}

img.insurance-logo {
  max-height: 100px;
  border-radius: unset;
  box-shadow: none;
  margin: 30px auto;
}

.mt-20 {
    margin-top: 20px;
}

.cta-fold .row {
  padding: 0;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .row.afspraak .btn {
      margin-bottom: 0;
  }
}

@media screen and (min-width: 544px) and (max-width: 767px) {
  .container {
      padding-left: 25px;
      padding-right: 25px;
  }

  img.side-img {
      height: auto;
      margin-top: 30px;
      width: 300px;
  }

  .margin-sm-0 {
      margin: 0 !important;
  }

  ul {
      margin: 0 !important;
  }

  img.img-round.social {
      margin-bottom: 0;
      width: 100px;
      height: auto;
  }
}

@media screen and (max-width: 543px) {
  .container {
      padding-left: 25px;
      padding-right: 25px;
  }

  .row > div {
      margin-bottom: 30px;
  }

  .margin-xs-0 {
      margin: 0 !important;
  }

  ul {
      margin: 0 !important;
  }

  img.side-img {
      height: auto;
      margin-top: 30px;
      margin-bottom: -250px;
      width: 300px;
  }

  img.img-round.fb {
      margin-bottom: 0;
      width: 100px;
      height: auto;
  }
}

.form-group {
  position: relative;
}

.form-group .btn-search {
    color: #ffffff;
    background-color: #102E4A;
    text-transform: uppercase;
    border-radius: 5px;color: #ffffff;
    border: none;
    font-size: 12px;
    padding: 10px 15px;
    font-weight: 500;
    margin: 10px 0 0 0;
    font-weight: 700;
    position: absolute;
    right: 6px;
    top: -10px;
}

.form-group .btn-search:hover {
    color: #ffffff;
}

.fancy-bullets li:before {
  font-weight: 700;
  content: "➤";
  width: 30px;
  height: 30px;
  background: rgb(189,219,254);
  background: linear-gradient(180deg, rgba(189,219,254,1) 50%, rgba(155,201,255,1) 50%);
  display: inline-block;
  position: absolute;
  left: 15px;
  border-radius: 50px;
  margin-top: -3px;
  padding-left: 9px;
  line-height: 30px;
  color: #2488fe;
  font-size: 16px;
}

.html-body H1,
.html-body H2,
.html-body H3,
.html-body H4,
.html-body H5,
.html-body H6,
.html-body p,
.html-body a,
.html-body li {
  word-wrap: break-word !important;
}

@media screen and (max-width: 375px) {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  p,
  a,
  li {
    word-wrap: break-word !important;
  }
}

.anchor-100-px {
  position: absolute;
  transform: translateY(-100px);
}

.anchor-30-vh {
  position: absolute;
  transform: translateY(-30vh);
}

.popupStyle {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 3px 5px -2px #ddd;
  border: 1px solid #d9d9d9;
}

@media screen and (max-width: 575px) {
  .popupStyle {
    border-width: 0px;
    box-shadow: none;
  }
}

.popup-content {
  padding: 0px !important;
  border: 0px !important;
  width: 90% !important;
  max-width: 500px !important;
}

.modal-ebook {
  font-size: 16px;
  position: unset !important;
  display: block !important;
}

.modal-ebook > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
  background-color: #1dafec;
  color: #fff;
}

.modal-ebook > .header h3 {
  margin: 0;
  line-height: 1;
  padding: 10px;
  color: #fff;
}

.modal-ebook > .content {
  width: 100%;
  text-align: center;
  padding: 10px 5px;
}

.modal-ebook > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal-ebook > .content img {
  margin-top: 20px;
  margin-bottom: 0;
}

.modal-ebook > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  opacity: 1;
}

.btn-orange {
  background-color: #ec921d !important;
}

.react-player__preview {
  //background-size: contain !important;
  background-repeat: no-repeat !important;
}

@import 'reactjs-popup/dist/index.css';
@import "~bootstrap/scss/bootstrap";

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
